import Dialog from '@mui/material/Dialog';
import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';

export default function DownloadDialog({ open, setOpen}) {

  const handleClose = () => {
    setOpen(false);
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="download-dialog-title"
      aria-describedby="download-dialog-description"
    >
      <DialogTitle id="download-dialog-title">
        {"Download Started"}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="download-dialog-description">
          Your download has started and the files are being processed. When processing 
          is complete, the download links will be emailed to you at your registered email address.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} autoFocus>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  )
}
