import { useState, useEffect, useRef } from 'react'

import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button'
import ButtonGroup from '@mui/material/ButtonGroup';

import 'ol/ol.css';
import Map from 'ol/Map.js';
import OSM from 'ol/source/OSM.js';
import TileLayer from 'ol/layer/Tile.js';
import VectorLayer from 'ol/layer/Vector'
import VectorSource from 'ol/source/Vector'
import View from 'ol/View.js';
import { Attribution, Zoom } from 'ol/control.js';
import  {transformExtent } from 'ol/proj.js';

import Draw, { createBox } from 'ol/interaction/Draw.js';

export default function AOIMapInput({ setMinLat, setMinLon, setMaxLat, setMaxLon }) {

  const [map, setMap] = useState()
  const [featuresLayer, setFeaturesLayer] = useState()
  const [drawingInteraction, setDrawingInteraction] = useState()
  const [selectedCoords , setSelectedCoords] = useState(['', '', '', ''])

  const mapRef = useRef();

  const handleDrawBox = () => {
    featuresLayer.getSource().clear();
    drawingInteraction.setActive(true);
  }

  const handleClearBox = () => {
    featuresLayer.getSource().clear();
    setMinLon('');
    setMinLat('');
    setMaxLon('');
    setMaxLat('');
  }

  useEffect(() => {
    if (selectedCoords.every((val) => val)) {
      console.log(selectedCoords);
      const [x1, y1, x2, y2] = selectedCoords;
      setMinLon(String(x1));
      setMinLat(String(y1));
      setMaxLon(String(x2));
      setMaxLat(String(y2));
    }
  }, [selectedCoords]);

  useEffect(() => {

    const initialFeaturesLayer = new VectorLayer({
      source: new VectorSource()
    });

    const drawInteraction = new Draw({
      type: 'Circle',
      geometryFunction: createBox(),
      source: initialFeaturesLayer.getSource(),
    })
    drawInteraction.setActive(false);

    const initialMap = new Map({
      target: mapRef.current,
      layers: [
        new TileLayer({
          source: new OSM(),
        }),
        initialFeaturesLayer
      ],
      view: new View({
        projection: 'EPSG:3857',
        center: [0, 0],
        zoom: 1,
        extent: [-20037508.34, -20048966.1, 20037508.34, 20048966.1]
      }),
      controls: [
        new Attribution({ collapsible: true, collapsed: true }),
        new Zoom()
      ],
    });
    initialMap.addInteraction(drawInteraction);

    setMap(initialMap);
    setFeaturesLayer(initialFeaturesLayer);
    setDrawingInteraction(drawInteraction);

    drawInteraction.on('drawend', (e) => {
      drawInteraction.setActive(false);
      setSelectedCoords(transformExtent(e.feature.getGeometry().getExtent(), 'EPSG:3857', 'EPSG:4326'));
    });
  }, []); 

  return (
      <Stack spacing={1}>
        <div ref={mapRef} className='map-container centered'></div>
        <Container>
          <ButtonGroup variant='contained'>
            <Button onClick={handleDrawBox}>Draw Box</Button>
            <Button onClick={handleClearBox}>Clear Box</Button>
          </ButtonGroup>
        </Container>
      </Stack>
      
  );
}