import { Amplify, API } from 'aws-amplify';
import dayjs from 'dayjs';

import API_CONFIG from '../../config/apigateway.json';

// Set up API Gateway auth and config
Amplify.configure({
    API: {
      endpoints: [
        {
          name: API_CONFIG.API_NAME,
          endpoint: API_CONFIG.API_URL
        }
      ]
    }
  });

/*
* Function to send RO metadata request through API Gateway and retrieve a list
* of RO files matching the submitted search criteria
*
* @param {object} request - Request object containing date, lat/lon values, and filetype info
* @param {Function} setFileList - Update function for fileList state variable
* 
* @return {None} - No return, updates fileList variable with the returned list using the provided function
*/
export async function queryFiles(request, setNumFiles, setFileList, setRequestID, setLoading) {

    const path = '/request/query';
    const options = {
      headers: {'Content-Type': 'application/json'},
      body: {
        "request": request,
      },
      timeout: 58 * 1000, /*API Gateway timeout is 30 seconds, anything taking longer than this will fail*/
    };
    try {
      setLoading(true)
      const response = await API.post(API_CONFIG.API_NAME, path, options);
      if (Object.keys(response).includes('file_list') && Object.keys(response).includes('requestID')) {
        // handle OK response
        setNumFiles(response['num_files'])
        setFileList(response['file_list']);
        setRequestID(response['requestID']);
        setLoading(false)
      }
      else {
        console.log('File query failed');
        console.log(response);
        setLoading(false)
      }
    }
    catch(err) {
      // handle errors
      console.log('File query failed');
      console.log(err);
      setLoading(false)
    }
};

/*
* Function to send list of RO files and trigger downloads for the indicated files
*
* @param {object} request - Request object containing fileList and fileType variables
* 
* @return {None} - No return, updates fileList variable with the returned list using the provided function
*/
export async function downloadFiles(request) {
  const path = '/request/download';
  const options = {
    headers: {'Content-Type': 'application/json'},
    body: {
      'request': request,
    },
    timeout: 30 * 1000, /*API Gateway timeout is 30 seconds, anything taking longer than this will fail*/
  };
  try {
    await API.post(API_CONFIG.API_NAME, path, options);
  }
  catch(err) {
    // handle errors
    console.log('File download request failed');
    console.log(err);
  }
};

/*
* Function to validate request before it is processsed 
* Checks for null fields, valid dates, and valid lat/lon
*
* @param {object} request - Request object containing dates, lat/lon values, and filetype
*
* @return {[bool, string]} - Array of length 2 with first value being validation status
*  and second being error message, if any
*/
export function validateRequest(request) {
  
    const date1 = dayjs(request['date1'], 'YYYY-MM-DD');
    const date2 = dayjs(request['date2'], 'YYYY-MM-DD');
    const lat1 = Number(request['lat1']);
    const lat2 = Number(request['lat2']);
    const lon1 = Number(request['lon1']);
    const lon2 = Number(request['lon2']);
  
    // Ensure all request fields have been entered
    if (Object.values(request).includes(null)) {
      return [false, 'Fill out all fields before submitting a request.'];
    }
    // Check start date before end date
    else if (date1.isAfter(date2)) {
      return [false, 'Start date cannot come before end date'];
    }
    else if ([lat1, lat2, lon1, lon2].includes(NaN)) {
      return [false, 'Lat/lon values must be a number'];
    }
    // Check min lat/lon smaller than max
    else if (lat1 >= lat2 || lon1 >= lon2) {
      return [false, 'Min lat/lon must be smaller than max lat/lon'];
    }
    // Check lat/lon within valid bounds
    else if (
      (lat1 < -90 || lat1 > 90) 
      || (lat2 < -90 || lat2 > 90) 
      || (lon1 < -180 || lon1 > 180) 
      || (lon2 < -180 || lon2 > 180)
    ) {
      return [false, 'Valid latitude range: [-90, 90]. Valid longitude range: [-180, 180].'];
    }
    else {
      return [true, ''];
    }
}