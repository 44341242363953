import TextField from '@mui/material/TextField';
import { useState } from 'react';


//Wrapper for material UI TextField component that accepts latitude or longitude values
export default function LatLonField({ value, id, label, updateFunction, disabled}) {
  
  const [helperText, setHelperText] = useState('');
  const [error, setError] = useState(false);

  const handleChange = (val) => {
    validateInput(val);
  }

  const validateInput = ((val) => {

    const ranges = {
      'lat': [-90, 90],
      'lon': [-180, 180],
    }

    const value = Number(val);
    //Determine if field is lat or lon
    const valueType = id.split('-')[1]

    if (Number.isNaN(value)) {
      setHelperText('Value is not a number');
      setError(true);
    }
    else if ( value < ranges[valueType][0] || value > ranges[valueType][1]) {
      setHelperText(`Enter a value between ${ranges[valueType][0]} and ${ranges[valueType][1]}`);
      setError(true);
    }
    else{
      setError(false)
      setHelperText('')
    }
    updateFunction(val);
  });

  return (
    <TextField 
      value={value}
      id={id}
      label={label} 
      error={error}
      helperText={helperText}
      onChange={(e) => {handleChange(e.target.value)}}
      InputLabelProps={{ shrink: true }}
      disabled={disabled}
      sx={{ flexGrow: 1 }}
    />
  )
}
