import { useState } from 'react';

import Grid from '@mui/material/Grid';
import LatLonField from './LatLonField.jsx';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';

export default function AOIManualInput({ minLat, maxLat, minLon, maxLon, setMinLat, setMaxLat, setMinLon, setMaxLon }) {

  const [maxRange, setMaxRange] = useState(false);

  const handleChange = (event) => {
    setMaxRange(event.target.checked)
    if (event.target.checked) {
      setMinLat('-90')
      setMaxLat('90')
      setMinLon('-180')
      setMaxLon('180')
    }
  }

  return (
      <Grid container direction='column' rows={4} justifyContent='flex-start' alignItems='center' spacing={2} sx={{ mt: .25 }}>
        <Grid item xs={1}>
          <LatLonField value={maxLat} id='max-lat' label='Max Lat' updateFunction={setMaxLat} disabled={maxRange}/> 
        </Grid>
        <Grid direction='row' container xs={1} item columns={2} spacing={0} justifyContent='center' >
          <Grid item xs={1}>
            <LatLonField value={minLon} id='min-lon' label='Min Lon' updateFunction={setMinLon} disabled={maxRange}/>
          </Grid>
          <Grid item xs={1}>
            <LatLonField value={maxLon} id='max-lon' label='Max Lon' updateFunction={setMaxLon} disabled={maxRange}/>
          </Grid>
        </Grid>
        <Grid item xs={1}>
          <LatLonField value={minLat} id='min-lat' label='Min lat' updateFunction={setMinLat} disabled={maxRange}/>
        </Grid>
        <Grid item xs={1}>
          <FormGroup>
            <FormControlLabel labelPlacement='top' control={<Switch checked={maxRange} onChange={handleChange} />} label="Full Globe" />
          </FormGroup>
        </Grid>
      </Grid>
  )
}
