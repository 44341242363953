import React from 'react'

import Container from '@mui/material/Container';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

export default function DateRangeSelection({ 
  minDate, maxDate, 
  startDate, endDate, 
  setStartDate, setEndDate,
}) {
  return (
    <Container id='date-selection'>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <b>Date Selection</b>
        <br/>
        <DatePicker
          label='Start Date' 
          minDate={minDate} 
          maxDate={maxDate} 
          onChange={(newValue) => setStartDate(newValue)}
          sx={{ m: 1 }}
        />
        <DatePicker
          label='End Date'
          minDate={startDate ? startDate : minDate} 
          maxDate={maxDate}
          onChange={(newValue) => setEndDate(newValue)}
          sx={{ m: 1 }}
        />
      </LocalizationProvider>
    </Container>
    
  )
}
