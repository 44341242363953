import Box from '@mui/material/Box';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import { FixedSizeList } from 'react-window';

export default function FileList( { numFiles, fileList }) {

  function renderRow(props) {
    const { index, style } = props;
    const split_str = fileList[index].split('/')
    const filename = split_str[split_str.length - 1];
    return (
      <ListItem style={style} key={index} component="div" disablePadding>
      <ListItemButton>
          <ListItemText primary={filename} />
      </ListItemButton>
      </ListItem>
    );
  }

  return (
    <Box id='file-list' className='centered'
      sx={{ width: '100%', height: 400, maxWidth: 600, bgcolor: 'background.paper' }}
    >

      <b>Showing first {fileList.length} of {numFiles} results</b>
      <br/>
      <FixedSizeList
        height={400}
        width={'100%'}
        itemSize={46}
        itemCount={fileList.length}
        overscanCount={5}
      >
        {renderRow}
      </FixedSizeList>
    </Box>
  )
}
