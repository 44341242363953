import Alert from '@mui/material/Alert';
import Container from '@mui/material/Container';

export default function ErrorAlert({ errorMessage, showError}) {
  return (
    <Container id='error-alert'>
      <br/>
      <Alert 
        onClose={() => {showError(false)}}
        severity="error"
        className='centered'
        sx={{width: '40%'}}>
        <strong>Invalid request</strong> — {errorMessage}
      </Alert>
      <br/>
    </Container>
    
  )
}