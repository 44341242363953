import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';

export default function Header({ signOut, email}) {
  return (
    <Box sx={{ flexGrow: 1}}>
      <AppBar position='static'>
        <Toolbar>
          <Typography variant='h6' component='div' sx={{ flexGrow: 1 }}>
            PlanetiQ Radio Occultation Data Viewer
          </Typography>
          <div>
            {email}
            <Button variant='contained' onClick={signOut} sx={{ ml: 2 }}>
              Sign out
            </Button>
          </div>
          
        </Toolbar>
      </AppBar>
    </Box>
  )
}
