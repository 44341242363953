import { useState } from 'react';

import Container from '@mui/material/Container';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

import AOIManualInput from './AOIManualInput.jsx';
import AOIMapInput from './AOIMapInput.jsx';

export default function AOISelection({ minLat, maxLat, minLon, maxLon, setMinLat, setMaxLat, setMinLon, setMaxLon }) {

  //Switch between manual input and box drawing
  //   Manual input: method == 0
  //  Drawing a box: method == 1
  const [method, setMethod] = useState(0);
  const handleChange = (event, newValue) => {
    setMethod(newValue);
  };

  return (
    <Container id='aoi-selection' sx={{ width: '100%'}}>
      <b>Area of Interest Selection</b>
      <Tabs 
        value={method}
        onChange={handleChange}
        centered>
        <Tab value={0} label="Input"/>
        <Tab value={1} label="Draw"/>
      </Tabs>

      {!method
        ? <AOIManualInput
            minLat={minLat}
            maxLat={maxLat}
            minLon={minLon}
            maxLon={maxLon}
            setMinLat={setMinLat} 
            setMaxLat={setMaxLat} 
            setMinLon={setMinLon} 
            setMaxLon={setMaxLon}
          />
        : <AOIMapInput
            setMinLat={setMinLat} 
            setMaxLat={setMaxLat} 
            setMinLon={setMinLon} 
            setMaxLon={setMaxLon}
          />
      }
    </Container>
  )
}
