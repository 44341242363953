import { useState } from 'react';
import { Amplify } from 'aws-amplify';
import { Authenticator } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import dayjs from 'dayjs';

// Material UI imports
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Grid';

//Local imports
import DateRangeSelection from './components/DateRangeSelection.jsx';
import Header from './components/Header.jsx';
import AOISelection from './components/AOISelection';
import Instructions from './components/Instructions.jsx';
import FileTypeSelection from './components/FileTypeSelection.jsx';
import FileList from './components/FileList.jsx';
import ErrorAlert from './components/ErrorAlert.jsx';
import DownloadDialog from './components/DownloadDialog.jsx';
import { queryFiles, downloadFiles, validateRequest } from './components/utils/requestUtils.js';

import COGNITO_CONFIG from './config/cognito.json';

import './styling/App.css';

Amplify.configure({
  Auth: {
    region: COGNITO_CONFIG.REGION,
    userPoolId: COGNITO_CONFIG.USERPOOLID,
    userPoolWebClientId: COGNITO_CONFIG.USERPOOLWEBCLIENTID,
    identityPoolId: COGNITO_CONFIG.IDENTITYPOOLID,
    mandatorySignIn: COGNITO_CONFIG.MANDATORYSIGNIN
  }
});

function App() {

  // Bounds of queryable date range
  const MIN_DATE = dayjs('01-19-2024', 'MM-DD-YYYY');
  const MAX_DATE = dayjs('03-31-2024', 'MM-DD-YYYY');

  // Variables used to build request 
  const [minLat, setMinLat] = useState('');
  const [maxLat, setMaxLat] = useState('');
  const [minLon, setMinLon] = useState('');
  const [maxLon, setMaxLon] = useState('');
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [fileType, setFileType] = useState('atmPrf');

  const [numFiles, setNumFiles] = useState(0)
  // List of files returned by request
  const [fileList, setFileList] = useState([]);

  // RequestID from current query
  const [requestID, setRequestID] = useState(null);

  const [columns, setColumns] = useState(1);
  const [downloadDialogOpen, setDownloadDialogOpen] = useState(false);
  const [showError, setShowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState(false);

  const submitQuery = () => {
    setNumFiles(100);
    setFileList([]);
    setShowError(false);
    // Build request object
    const request = {
      'date1': startDate ? startDate.format('YYYY-MM-DD') : null,
      'date2': endDate ? endDate.format('YYYY-MM-DD') : null,
      'lat1': minLat,
      'lat2': maxLat,
      'lon1': minLon,
      'lon2': maxLon,
      'datatype': fileType
    }
    
    const [validated, errorString] = validateRequest(request);

    if(validated) {
      setColumns(2);
      queryFiles(request, setNumFiles, setFileList, setRequestID, setLoading);
    }
    else {
      setColumns(1);
      setErrorMessage(errorString);
      setShowError(true);
    }
  }

  const submitDownload = (user_email) => {
    setDownloadDialogOpen(true);
    
    //Build request object
    const request = {
      'requestID': requestID,
      'numFiles': numFiles,
      'email': user_email,
      'datatype': fileType,
    }
    try{
      downloadFiles(request);
    }
    catch(err) {
      console.log('Error starting file download');
      console.log(err);
    }
  }

  /*
   Docs for Amplify Authenticator component can be found here 
   https://ui.docs.amplify.aws/react/connected-components/authenticator 
   */
  return (
    <Authenticator loginMechanisms={['email']} hideSignUp={true}>
      {({ signOut, user }) => (
      <div className="App">
        <Header signOut={signOut} email={user.attributes.email}/>
        <div className='app-main'>
          <Instructions/>
          { showError
            ? <ErrorAlert errorMessage={errorMessage} showError={setShowError} />
            : <></>
          }
          <br/>
          <Grid container columns={columns} alignItems='stretch' justifyContent='space-evenly'>
            <Grid item xs={1}>
              <Stack alignItems='center' justifyContent='flex-start' spacing={3} sx={{ textAlign: 'center' }}>
                <DateRangeSelection
                  minDate={MIN_DATE}
                  maxDate={MAX_DATE}
                  startDate={startDate}
                  endDate={endDate}
                  setStartDate={setStartDate}
                  setEndDate={setEndDate}
                />

                <AOISelection 
                  minLat={minLat}
                  maxLat={maxLat}
                  minLon={minLon}
                  maxLon={maxLon}
                  setMinLat={setMinLat} 
                  setMaxLat={setMaxLat} 
                  setMinLon={setMinLon} 
                  setMaxLon={setMaxLon}
                />

                <FileTypeSelection 
                  fileType={fileType}
                  setFileType={setFileType}/>

                <Container>
                  <Button variant='contained' onClick={() => submitQuery()}>
                    Submit
                  </Button>
                </Container>
              </Stack>
            </Grid>
            <Grid item xs={1}>
              {loading ? <Container style={{width: '100%', height: '60%', display: 'flex', alignItems: 'center', justifyContent: 'center'}}><span className="loader"></span></Container> : <></> }
              { fileList.length
                ? <Stack alignItems='flex-start' justifyContent='space-between' spacing={3}>
                    <FileList numFiles={numFiles} fileList={fileList}/>
                    <br/>
                    <Container>
                      <Button className='centered' variant='contained' onClick={() => submitDownload(user.attributes.email)}>
                        Download Files
                      </Button>
                    </Container>
                  </Stack>
                : <></>
              }
            </Grid>
          </Grid>
          <DownloadDialog open={downloadDialogOpen} setOpen={setDownloadDialogOpen} /> 
          
        </div>
      </div>
      )}
    </Authenticator>
    
  );
}

export default App;
