import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import Container from '@mui/material/Container';

export default function FileTypeSelection({ fileType, setFileType }) {

  return (
    <Container>
      <b>File Type Selection</b>
      <br/>
      <FormControl>
        <RadioGroup
          name="row-radio-buttons-group"
          value={fileType}
          onChange={(e, value) => setFileType(value)}
        >
          <FormControlLabel value="conPhs" control={<Radio />} label="Neutral Atmosphere Calibrated Phase" />
          <FormControlLabel value="atmPrf" control={<Radio />} label="Neutral Atmosphere Refractivity Retrieval" />
          <FormControlLabel value="podTc2" control={<Radio />} label="Ionosphere Total Electron Content" />
          <FormControlLabel value="scnPhs" control={<Radio />} label="Ionosphere Scintillation Calibrated Phase" />

        </RadioGroup>
      </FormControl>
    </Container>
  )
}
