import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';

export default function Instructions() {

  return (
    <Container sx={{ textAlign: 'center' }}>
      <Typography mt={2} variant='h4' component='div' sx={{ flexGrow: 1 }}>
        Query to download PlanetiQ radio occultation data.
      </Typography>
      <Typography variant='p' component='div' sx={{ flexGrow: 1, fontSize: '1.2em' }}>
          Instructions: Select a date range and area of interest to view the available files for the listed data types.  You'll then get the option to create a download script for said data files.
      </Typography>
      <Typography variant='p' component='div' sx={{ flexGrow: 1}}>
      To programatically query and download data, please use <a href='/webui/files/data_piqer.zip' download='data_piqer.zip'>this</a> python script.
      </Typography>
    </Container>
  )
}
